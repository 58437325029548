import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Box,
  Grid,
  CircularProgress,
  Autocomplete,
  IconButton,
  Typography,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ImageUpload from "./ImageUpload"

function UploadDialog({ open, handleClose, categories, part }) {
  const [succes, setSucces] = useState("")
  const [fout, setFout] = useState("")
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [purchprice, setPurchprice] = useState("")
  const [sku, setSku] = useState("")
  const [quantity, setQuantity] = useState(1)
  const [weight, setWeight] = useState(10)
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState("")
  const [extracategory, setExtracategory] = useState("")
  const [extraPartNumber, setExtraPartNumber] = useState("")
  const [newImage, setNewImage] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (part) {
      setName(part.name || "")
      setDescription("")

      if (part.price && part.price !== "Niet beschikbaar") {
        const rawPrice = part.price.replace("€", "").replace(",", ".").trim()
        const calculatedPrice = rawPrice * 1.2

        setPrice(calculatedPrice)
        setPurchprice(rawPrice)
      } else {
        setPrice(0.0)
        setPurchprice(0.0)
      }

      const fetchData = async () => {
        try {
          const response = await fetch(`https://playmohub.bytechplay.nl/api/parts`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ partNumbers: [part.partNumber] }),
          })
          const onderdeel = await response.json()

          if (onderdeel && onderdeel.length > 0) {
            const sets = onderdeel[0].sets || []
            const setsString = sets.join(", ")
            const onderdeelNummer = part.partNumber || ""

            // Bouw de description op in de gewenste volgorde
            setDescription(`Playmobil ${setsString}\nPlaymobil onderdeel ${onderdeelNummer}`)
            setName(onderdeel[0].partnaam || "")
          }
        } catch (error) {
          console.error("Fout bij het ophalen van de gegevens:", error)
        }
      }

      fetchData()
    }
  }, [part])

  useEffect(() => {
    if (category) {
      const categoryPattern = /^(\d+)\.(\d+)/
      const match = categoryPattern.exec(category.name)
      if (match && part?.partNumber) {
        const firstNumber = match[1].padStart(2, "0")
        const numberOfZeros = match[2].length === 2 ? "00" : "000"
        setSku(`${firstNumber}_${match[1]}${match[2]}${numberOfZeros}_${part.partNumber}`)
      } else {
        setSku("Zelf invullen...")
      }
    }
  }, [category, part?.partNumber])

  const importProducts = async () => {
    try {
      setLoading(true)

      const data = {
        sku: sku,
        name: name,
        type: "simple",
        status: "pending",
        manage_stock: true,
        stock_quantity: quantity,
        weight: weight.toString(),
        regular_price: price ? price.toString().replace(",", ".") : "99.99",
        description: description,
        meta_data: [
          {
            key: "inkoopsprijs",
            value: purchprice ? purchprice.toString().replace(",", ".") : "99.99",
          },
        ],
        categories: [
          ...(category.id
            ? [
                {
                  id: 406,
                },
                {
                  id: category.id,
                },
                {
                  id: category.parent,
                },
              ]
            : []),
          ...(extracategory?.id
            ? [
                {
                  id: extracategory.id,
                },
                {
                  id: extracategory.parent,
                },
              ]
            : []),
        ],
        images: [
          {
            src: newImage ? `${newImage}` : `${part.image}.png`,
          },
        ],
      }

      console.log(data)

      const product = await axios.post("https://www.playmodok.nl/wp-json/wc/v3/products", data, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD,
        },
      })

      setSucces(product.data)
    } catch (error) {
      console.error("Error tijdens het uploaden van producten:", error)
      setFout(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  const handleReset = () => {
    setSucces("")
    setFout("")
    setPrice(0.0)
    setPurchprice(0.0)
    setQuantity(1)
    setWeight(10)

    setSku("")
    setName(part.name || "")
    setCategory("")
    setExtracategory("")
    setExtraPartNumber("")
    setNewImage()
  }

  const handleCalculatePrice = () => {
    if (price === 0 || price === "" || price === null || price === undefined) {
      if (purchprice !== 0 && purchprice !== "" && purchprice !== null && purchprice !== undefined) {
        const calculatedPrice = (parseFloat(purchprice) * 1.2).toFixed(2)
        setPrice(calculatedPrice)
      }
    } else {
      const calculatedPurchPrice = (parseFloat(price) * 0.8).toFixed(2)
      setPurchprice(calculatedPurchPrice)
    }
  }

  const handleAddExtraPart = async () => {
    if (!extraPartNumber.trim()) return

    try {
      const response = await fetch(`https://api.playmodok.nl/api/parts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ partNumbers: [extraPartNumber] }),
      })
      const onderdeel = await response.json()

      if (onderdeel && onderdeel.length > 0) {
        const newSets = onderdeel[0].sets || []
        const newSetsString = newSets.join(", ")
        const newOnderdeelNummer = extraPartNumber

        setDescription((prevDescription) => {
          const [prevSetsLine, prevOnderdeelLine] = prevDescription.split("\n")
          const updatedSetsLine = `${prevSetsLine}, ${newSetsString}`
          const updatedOnderdeelLine = `${prevOnderdeelLine}, ${newOnderdeelNummer}`

          return `${updatedSetsLine}\n${updatedOnderdeelLine}`
        })

        setName((prevName) => `${prevName} + ${onderdeel[0].partnaam || ""}`)
        setExtraPartNumber("")
      }
    } catch (error) {
      console.error("Fout bij het ophalen van de gegevens:", error)
    }
  }

  const handleFieldChange = (setter) => (event) => setter(event.target.value)

  const handleImageUpload = (imagePath) => {
    setNewImage(imagePath)
  }

  if (!part) {
    return null
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle style={{ color: succes ? "green" : fout ? "red" : "black" }}>
        {succes ? `Product ${succes.sku} succesvol geüpload.` : fout ? `Fout: ${fout}` : "Upload een onderdeel naar de website"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box textAlign="center" sx={{ marginRight: 1 }}>
              <ImageUpload part={part} onImageUpload={handleImageUpload} />
              {/* Eerste vierkantje met part.image */}
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  overflow: "hidden",
                  marginRight: 1,
                  marginTop: 1, // Ruimte tussen de twee vierkantjes
                  position: "relative",
                  display: "inline-block", // Zorgt ervoor dat de vakjes naast elkaar staan
                }}
              >
                {part.image !== "Niet beschikbaar" ? (
                  <img src={part.image} alt="Afbeelding" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                ) : (
                  "Niet beschikbaar"
                )}
              </Box>

              {/* Tweede vierkantje met newImage of een rood kruis */}
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-block", // Zorgt ervoor dat de vakjes naast elkaar staan
                }}
              >
                {newImage ? (
                  <img src={newImage} alt="Nieuw Afbeelding" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="red"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      X
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField label="Naam" size="small" value={name} onChange={handleFieldChange(setName)} fullWidth margin="dense" />
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                label="Verkoopprijs"
                size="small"
                value={price}
                onChange={handleFieldChange(setPrice)}
                fullWidth
                margin="dense"
                required
                InputProps={{
                  startAdornment: <span style={{ marginRight: 8 }}>€</span>,
                }}
                error={price === "0" || price === ""}
                sx={{
                  "& .MuiInputBase-root": {
                    color: price === "0" || price === "" ? "red" : "inherit",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: price === "0" || price === "" ? "red" : "inherit",
                    },
                    "&:hover fieldset": {
                      borderColor: price === "0" || price === "" ? "red" : "inherit",
                    },
                  },
                }}
              />
              <TextField
                label="Inkoopprijs"
                size="small"
                value={purchprice}
                onChange={handleFieldChange(setPurchprice)}
                fullWidth
                margin="dense"
                required
                InputProps={{
                  startAdornment: <span style={{ marginRight: 8 }}>€</span>,
                }}
              />
              <Button onClick={handleCalculatePrice} color="primary" size="small">
                Prijs...
              </Button>
            </Box>
            <FormControl fullWidth margin="dense" sx={{ marginTop: 0, marginBottom: 0 }}>
              <Autocomplete
                size="small"
                options={categories}
                getOptionLabel={(option) => option.name || ""}
                value={category || null}
                onChange={(event, newValue) => setCategory(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Categorie (incl. bovenliggende)" variant="outlined" margin="dense" />
                )}
              />
            </FormControl>
            <TextField label="SKU" size="small" value={sku} onChange={handleFieldChange(setSku)} fullWidth margin="dense" required />
            <TextField
              label="Hoeveelheid"
              size="small"
              value={quantity}
              onChange={handleFieldChange(setQuantity)}
              fullWidth
              margin="dense"
              type="number"
              required
            />
            <TextField
              label="Gewicht (gram)"
              size="small"
              value={weight}
              onChange={handleFieldChange(setWeight)}
              fullWidth
              margin="dense"
              type="number"
              required
            />
            <FormControl fullWidth margin="dense" sx={{ marginTop: 0, marginBottom: 0 }}>
              <Autocomplete
                size="small"
                options={categories}
                getOptionLabel={(option) => option.name || ""}
                value={extracategory || null}
                onChange={(event, newValue) => setExtracategory(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Extra Categorie (zonder parent)" variant="outlined" margin="dense" />
                )}
              />
            </FormControl>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                label="Subonderdeel"
                size="small"
                value={extraPartNumber}
                onChange={handleFieldChange(setExtraPartNumber)}
                fullWidth
                margin="dense"
              />
              <IconButton onClick={handleAddExtraPart} color="primary" size="small" sx={{ padding: 0.5 }}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <TextField
          label="Korte omschrijving"
          value={description}
          onChange={handleFieldChange(setDescription)}
          fullWidth
          margin="dense"
          multiline
          rows={5}
        />
      </DialogContent>
      <DialogActions>
        {loading ? null : (
          <Button onClick={handleReset} variant="contained" color="primary" size="small">
            Reset
          </Button>
        )}
        {succes ? null : loading ? (
          <CircularProgress size={20} style={{ color: "#059be7" }} />
        ) : (
          <Button onClick={importProducts} variant="contained" color="primary" size="small">
            Uploaden
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default UploadDialog
