import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { Box, Typography, Button, Stack } from "@mui/material"

const ImageUpload = ({ part, onImageUpload }) => {
  const [files, setFiles] = useState([])
  const [newImage, setNewImage] = useState()

  useEffect(() => {
    if (part?.image) {
      setFiles([
        {
          preview: part.image,
          name: "Existing Image",
        },
      ])
    }
  }, [part])

  // Clean up object URLs to avoid memory leaks
  useEffect(() => {
    return () => {
      files.forEach((file) => {
        if (file.preview.startsWith("blob:")) {
          URL.revokeObjectURL(file.preview)
        }
      })
    }
  }, [files])

  const onDrop = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp"],
    },
    multiple: false,
  })

  const handleUpload = async () => {
    if (files.length === 0) {
      alert("Please select a file!")
      return
    }

    const formData = new FormData()
    formData.append("image", files[0])

    try {
      const response = await fetch("https://playmohub.bytechplay.nl/api/uploadimage", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json", // Dit kan helpen om CORS-gerelateerde issues op te lossen
        },
      })

      if (response.ok) {
        const data = await response.json()
        const uploadedImagePath = `https://playmohub.bytechplay.nl${data.filePath}`
        setNewImage(uploadedImagePath)
        console.log(newImage)

        // Roep de callback aan om de oudercomponent te informeren
        if (onImageUpload) {
          onImageUpload(uploadedImagePath)
        }
      } else {
        alert("File upload failed!")
      }
    } catch (error) {
      console.error("Upload error:", error)
      alert("An error occurred!")
    }
  }

  const handleRemove = () => {
    // Reset het bestand naar het oorspronkelijke plaatje (part.image)
    if (part?.image) {
      setFiles([
        {
          preview: part.image,
          name: "Original Image",
        },
      ])
    } else {
      setFiles([]) // Als er geen afbeelding is, set files naar een lege array
    }

    // Reset de newImage in de parent als dat nodig is
    if (onImageUpload) {
      onImageUpload(null) // Reset de newImage in de parent
    }
  }

  return (
    <Stack spacing={1} alignItems="center">
      {/* Dropzone */}
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #ccc",
          borderRadius: 2,
          padding: 2,
          textAlign: "center",
          width: "100%",
          maxWidth: 400,
          cursor: "pointer",
          backgroundColor: isDragActive ? "#f0f0f0" : "#fff",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body1">Zet hier je afbeelding...</Typography>
        ) : (
          <Typography variant="body1">Sleep de afbeelding hierin of klik...</Typography>
        )}
      </Box>

      {/* Preview */}
      {files.length > 0 && (
        <Box
          sx={{
            marginTop: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6">Voorbeeld:</Typography>
          <img
            src={files[0].preview}
            alt="Preview"
            style={{
              marginTop: 10,
              maxWidth: "100%",
              maxHeight: 200,
              borderRadius: 8,
            }}
          />
        </Box>
      )}

      {/* Buttons */}
      {files.length > 0 && files[0]?.preview !== part?.image && (
        <Stack direction="row" spacing={2}>
          <Button size="small" variant="contained" color="primary" onClick={handleUpload}>
            Uploaden...
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={handleRemove}>
            Verwijder
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export default ImageUpload
