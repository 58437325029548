import React from "react"
import { useDropzone } from "react-dropzone"
import { Typography } from "@mui/material"

const NieuwProduct = ({ onExtractedNumbers }) => {
  const extractPartNumbers = (filename) => {
    const matches = filename.match(/\d{8}/g) // Zoek naar alle 8-cijferige getallen
    return matches || [] // Retourneer de matches of een lege array als er geen zijn
  }

  const onDrop = (acceptedFiles) => {
    const extractedNumbers = acceptedFiles.flatMap((file) => extractPartNumbers(file.name))
    if (onExtractedNumbers) {
      onExtractedNumbers(extractedNumbers)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg", ".bmp", ".gif", ".webp"],
    },
  })

  return (
    <div
      {...getRootProps()}
      style={{
        display: "inline-block",
        marginLeft: "10px",
        padding: 7,
        border: "2px dashed #ccc",
        borderRadius: 2,
        width: "100%",
        maxWidth: 200,
        cursor: "pointer",
        backgroundColor: isDragActive ? "#f0f0f0" : "#fff",
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? <Typography variant="body2">Zet neer...</Typography> : <Typography variant="body2">Sleep of klik...</Typography>}
    </div>
  )
}

export default NieuwProduct
